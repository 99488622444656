import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {
  title = 'bizness';

  public textli: any = [
    "Методику создания успешной сплоченной команды, слаженного механизма, который четко работает!",
    "Книгу успеха со всеми регламентами",
    "Успешную мотивацию ОП",
    "Скрипты для ОП",
    "Смыслы и офферы для успешного продвижения",
    "Эффективные способы рекламы",
    "Эффективное планирование на год",
    "Таблицы ежедневного отчета для ОП",
    "Способы анализа продаж - повышения их эффективности, механизмы для повышения эффективности",
    "Регламенты работы в CRM",
    "Системы обучения ",
    "Системы бережливого производства",
    "Системы оптимизации расходов и анализ Вашей финансовой деятельности, с выводами об узких местах",
    "Анализ структуры Вашей компании",
    "Таблицу анализа конкурентов",
    "<b>И самое главное - личные консультации, помощь и рекомендации успешного бизнесмена с опытом работы более 25 лет - и это бесценно!</b>"
  ];

  public datecontact: any = {
    date: "",
    phone: "",
    adress: "",
    money: "",
    term: "",
    schedule: ""    
  }


  public user:any = {
    name: "",
    phone: ""
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getDate();
  }

  forma(){
    let  baseUrl:string = 'https://xn-----clcbqcnhec7bfdq3akbw2hvc.xn--p1ai/telegram/?'+'name='+this.user.name+'&phone='+this.user.phone;
    //console.log(baseUrl);
    this.http.get(baseUrl).subscribe(data => {    
    });  
  }

  getDate(){
    let  baseUrl:string = 'https://xn-----clcbqcnhec7bfdq3akbw2hvc.xn--p1ai/data/date.php';
    this.http.get(baseUrl).subscribe(( jsonData: any )  => {
      this.datecontact = jsonData;
    });  
  }

}
