import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public datecontact: any = {
    date: "",
    phone: "",
    adress: "",
    money: "",
    term: "",
    schedule: ""    
  }

  constructor(private http: HttpClient) { 
  };

  ngOnInit() {
    this.getDate();
  }

  getDate(){
    let  baseUrl:string = 'https://xn-----clcbqcnhec7bfdq3akbw2hvc.xn--p1ai/data/date.php';
    this.http.get(baseUrl).subscribe(( jsonData: any )  => {
      this.datecontact = jsonData;
    });  
  }




  


}
