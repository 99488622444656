import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SoglashenieComponent } from './soglashenie/soglashenie.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'soglashenie', component: SoglashenieComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
